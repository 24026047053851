import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import "./App.css";
import { CasaLogo1 } from "./icons/icons";

import "./App.css";
import { TemplateModel, TemplateDescriptor, Props } from "./lib/data";

import { Settings } from "luxon";
import Template1 from "./templates/Template1";
import Template2 from "./templates/Template2";
import Template3 from "./templates/Template3";
import Template4 from "./templates/Template4";
import Template5 from "./templates/Template5";
import Template6 from "./templates/Template6";
import Template7 from "./templates/Template7";
import Template8 from "./templates/Template8";
import Template9 from "./templates/Template9";
import Template10 from "./templates/Template10";
import Template11 from "./templates/Template11";
import Template12 from "./templates/Template12";
import Template13 from "./templates/Template13";
import Template14 from "./templates/Template14";
import Template15 from "./templates/Template15";

import Template16 from "templates/Template16";
import Template17 from "templates/Template17";
import TemplateVideo from "templates/TemplateVideo";
import TemplatePicture from "templates/TemplatePicture";
import TemplateTop10 from "templates/TemplateTop10";
import useAppStore from "store/appstore";
Settings.defaultZone = "Europe/Bucharest";

const Templates: { [key: string]: React.FC<Props> } = {
  "1": Template1, // 1 game, team icons, 3 odds no label, arrows for trends - main..
  "2": Template2, // 1 game, 3 odds no label, arrows for trends - main..
  "3": Template3, // 1 game, 5 odds with long label, one row, eg. score corect
  "4": Template4, // 1 game, 20 odds with long label, eg. score corect
  "5": Template5, // 1 game, 3 odds no label - main..
  "6": Template6, // 1 game, 8 odds with long label, eg. score corect
  "7": Template7, // 1 game, 4 odds with long label, eg. score corect
  "8": Template8, // 1 game, 5 odds with long label, eg. score corect
  "9": Template9, // Winner of cup 4 x1o-> Redbull 10.00, Mercedes: 8.49,..
  "10": Template10, // Tournament table 6m x3o
  "11": Template11, //wip Top 10 x3o, treb 6 odds
  "12": Template12, // Speciale  Real - Barca: F.TORRES MARCHEAZA PRIMUL GOL SI REZULTATUL FINAL 2:1 - Da 13.90
  "13": Template13, // Odds booster - "to fix"
  "14": Template14, // 1 game large x3o+ table of 3 x3o,
  "15": Template15, // Oferta 3h, starting soon
  "16": Template16, // oferta plus
  "17": Template17, // Competition table 10mx6o

  "18": TemplateTop10, // Competition table 10mx6o
  "20": TemplateVideo,
  "21": TemplatePicture,
};

const FEED_API = process.env.REACT_APP_FEED_API as string;
const getFeedItemsFromServer = (): Promise<TemplateDescriptor[]> => {
  return fetch(FEED_API)
    .then((data) => data.json())
    .then((json) => {
      let items = json as {
        date: string;
        templateModel: TemplateModel;
        template_id: string;
        mediaURL?: string;
      }[];
      let fixed_items = items.map((j) => {
        return {
          template: {
            ...j.templateModel,
            background: {
              backgroundImageURL: "/ic_background.jpg",
              logo: CasaLogo1,
            },
          },
          template_id: j.template_id,
          mediaURL: j.mediaURL,
        };
      });

      return fixed_items.filter(
        (item) => Templates[item.template_id] !== undefined
      );
    });
};

const App: React.FC = () => {
  const setPages = useAppStore((state) => state.setPages);
  useQuery(["todos"], getFeedItemsFromServer, {
    refetchIntervalInBackground: true,
    refetchInterval: 5 * 60 * 1000,
    retry: true,
    onSuccess: (data) => {
      setPages(data);
    },
    onError: (error) => {
      console.error(`Error loading feed : ${error}`);
    },
  });
  const uniqueIndex = useAppStore((state) => state.uniqueIndex);
  const currentPage = useAppStore((state) => state.currentPage);
  const changePage = useAppStore((state) => state.changePage);
  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  useEffect(() => {
    document.addEventListener("click", changePage);
    return () => {
      document.removeEventListener("click", changePage);
    };
  });

  if (currentPage === undefined) {
    return <></>;
  }
  const Template = Templates[currentPage.template_id];

  return (
    <>
      {
        <Template
          key={uniqueIndex}
          template={currentPage.template}
          mediaURL={currentPage.mediaURL}
        />
      }
    </>
  );
};

export default App;
